//import s from './MainPanelAdministration.module.css';
import React from 'react';
import { connect } from 'react-redux';
import Fuel from './Fuel';

class FuelContainer extends React.PureComponent {

    render() {
        return (<Fuel            
        />)
    }
}

const mapStateToProps = (state) => {
    return {
    }
};

export default connect(mapStateToProps, {


})(FuelContainer)
