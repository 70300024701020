import * as React from 'react';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { Stack } from '@mui/material';
import TableType2 from '../../components/TableType2';
import { GridEditSingleSelectCell, useGridApiContext } from '@mui/x-data-grid';

function CustomTypeEditComponent(props) {
    const apiRef = useGridApiContext();
  
    const handleValueChange = async () => {
      await apiRef.current.setEditCellValue({
        id: props.id,
        field: 'group_id',
        value: '',
      });
    };
  
    return <GridEditSingleSelectCell onValueChange={handleValueChange} {...props} />;
  }

const Prices = () => {

    const words = useSelector(state => state.language.words)
    const fuel = useSelector(state => state.fuelSettings.fuel)
    const regions = useSelector(state => state.regions.regions)
    const groups = useSelector(state => state.groupe.groups)
    const prices = useSelector(state => state.fuelSettings.prices)

    const data = prices.map(p => ({ ...p, start_date: dayjs(p.start_date).$d, end_date: dayjs(p.end_date).$d, region_id: regions.filter(r => r.id === p.region_id)[0].name, group_id: groups.filter(g => g.id === p.group_id)[0].name, fuel_name_id: fuel.filter(f => f.id === p.fuel_name_id)[0].name }))
    const dataRegion = regions.map(r=>r.name)
    let dataGrups = groups.map(g=>g.name)
    let datafuel = fuel.map(f=>f.name)
    // function valueGetUnit(params) {
    //     console.log(params);

    //     return params.$d
    //   }

    const columnsObj = [
        {
            field: 'id',
            headerName: 'ID',
            align: 'center',
            headerAlign: 'center',
            width: 60,
            editable: false
        },
        {
            field: 'region_id',
            headerName: words.region,
            type: 'singleSelect',
            valueOptions: dataRegion,
            editable: true,
            headerAlign: 'center',
            align: 'left',
            width: 150,
            renderEditCell: (params) => <CustomTypeEditComponent {...params} />,
                        
        },
        {
            field: 'group_id',
            headerName: words.groupe,
            align: 'left',
            headerAlign: 'center',
            width: 180,
            type: 'singleSelect',
            valueOptions: ({ row }) => {
                                
                if (!row) {
                  return dataGrups
                }
        
                return groups.filter(g=>g.region_id===regions.filter(r=>r.name===row.region_id)[0].id).map(g=>g.name)
              },
            editable: true,
        },
        {
            field: 'fuel_name_id',
            headerName: words.np,
            align: 'left',
            headerAlign: 'center',
            width: 120,
            type: 'singleSelect',
            valueOptions: datafuel,
            editable: true,
        },
        {
            field: 'price',
            headerName: words.price,
            align: 'right',
            headerAlign: 'center',
            width: 100,
            editable: false,
        },
        {
            field: 'nds',
            headerName: words.nds,
            align: 'center',
            headerAlign: 'center',
            width: 80,
            editable: true,
            type: 'boolean',
           
            //renderCell: (params) => <Checkbox checked={params.value} onChange={handleState} />
            // renderCell: (params) => params.value ? <CheckIcon color='success' /> : <RemoveOutlinedIcon sx={{ mt: 0.5 }} color='disabled' />,
        },
        {
            field: 'value_nds',
            headerName: words.value_nds,
            align: 'right',
            headerAlign: 'center',
            width: 100,
            editable: false,
        },
        {
            field: 'price_without_nds',
            headerName: words.price_without_nds,
            align: 'right',
            headerAlign: 'center',
            width: 150,
            editable: false,
        },
        {
            field: 'start_date',
            headerName: words.start_date,
            headerAlign: 'center',
            align: 'center',
            width: 140,
            editable: true,
            type: 'date',
            //valueGetter: valueGetUnit,
            // renderCell: (params) => { console.log(params);
            //  return <div style={{marginBottom: '4px', marginTop: '4px'}}>
            //     <LocalizationProvider dateAdapter={AdapterDayjs}>
            //     <DateRange localeText={{ start: 'Check-in', end: 'Check-out' }} />
            //     </LocalizationProvider>
            //     {/* <Typography sx={{ textDecoration: 'none', fontWeight: '600', fontSize: '0.82rem', lineHeight: '1' }} color='success' textAlign="center" >с {params.row.start_date}</Typography>
            //     <Typography sx={{ textDecoration: 'none', fontSize: '0.82rem', lineHeight: '1' }} color='error' textAlign="center" >по {params.row.end_date}</Typography> */}
            //   </div>},
        },
        {
            field: 'end_date',
            headerName: words.end_date,
            headerAlign: 'center',
            align: 'center',
            width: 140,
            editable: true,
            type: 'date',
            // renderCell: (params) => { console.log(params);
            //  return <div style={{marginBottom: '4px', marginTop: '4px'}}>
            //     <LocalizationProvider dateAdapter={AdapterDayjs}>
            //     <DateRange localeText={{ start: 'Check-in', end: 'Check-out' }} />
            //     </LocalizationProvider>
            //     {/* <Typography sx={{ textDecoration: 'none', fontWeight: '600', fontSize: '0.82rem', lineHeight: '1' }} color='success' textAlign="center" >с {params.row.start_date}</Typography>
            //     <Typography sx={{ textDecoration: 'none', fontSize: '0.82rem', lineHeight: '1' }} color='error' textAlign="center" >по {params.row.end_date}</Typography> */}
            //   </div>},
        },
        {
            field: 'individual',
            headerName: words.individual,
            align: 'center',
            headerAlign: 'center',
            width: 140,
            editable: true,
            type: 'boolean',
           
            //renderCell: (params) => <Checkbox checked={params.value} onChange={handleState} />
            // renderCell: (params) => params.value ? <CheckIcon color='success' /> : <RemoveOutlinedIcon sx={{ mt: 0.5 }} color='disabled' />,
        }

    ]

    return (<Stack
        direction="row"
        spacing={2}
        sx={{
            justifyContent: "space-between",
            alignItems: "flex-start",
        }}
    >
        <Stack
            direction="column"
            spacing={2}
            sx={{
                justifyContent: "flex-end",
                alignItems: "flex-end",
                width: 'min-content'
            }}
        >
                     

            <div style={{ marginTop: '15px' }}>
                <TableType2 data={data} columns={columnsObj} />
            </div>
        </Stack>
    </Stack>

    )
}

export default Prices