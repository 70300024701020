import * as React from 'react';
import dayjs from 'dayjs';
import NewGroupe from '../../../Forms/NewGroupe';

const AddGroupe = ({close}) => {     

    return (
       
            <NewGroupe close={close} />
       
    )
}

export default AddGroupe