//import s from './MainPanelAdministration.module.css';
import React from 'react';
import { connect } from 'react-redux';
import AddRegion from './AddRegion';

class AddRegionContainer extends React.PureComponent {

    render() {
        return (<AddRegion
            close={this.props.close}
        />)
    }
}

const mapStateToProps = (state) => {
    return {
    }
};

export default connect(mapStateToProps, {
   

})(AddRegionContainer)
