import React from 'react';
import { useForm, Controller } from "react-hook-form";
import {
  Box,
  Button,
  FormControlLabel,
  styled,
  Switch,
  //Divider,
  TextField,
} from "@mui/material";
import Grid from '@mui/material/Grid2';
import { useSelector } from 'react-redux';
//import { ContentCutOutlined } from '@mui/icons-material';

const myHelper = {
  email: {
    required: "Заполните поле",
    pattern: "Не корректный формат e-mail"
  },
  password: {
    required: "Заполните поле"
  }
};


const NewObjectType = ({ close }) => {
  const words = useSelector(state => state.language.words)
  //const dispatch = useDispatch()

  const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
      borderRadius: 22 / 2,

      '&::before, &::after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 16,
        height: 16,

      },
      '&::before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.success.main),
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,

      },
      '&::after': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.success.main),
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 12,

      }
    },
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      backgroundColor: '#fff',
      width: 16,
      height: 16,
      margin: 2,
    },
  }));


  // const setOpenFormReg = (data) => {
  //   dispatch({ type: 'AUTH/SET_OPEN_FORM_REGISTRATION', data })
  // }

  const { control, handleSubmit } = useForm({
    reValidateMode: "onBlur"
  });


  const handleOnSubmit = (evt) => {
    console.log(evt);

    close(false)
  };

  const closeForm = () => {
    close(false)
  }

  return (
    <Box component="form" onSubmit={handleSubmit(handleOnSubmit)} sx={{ borderRadius: '10px', backgroundColor: 'rgb(237 237 237)', p: 3 }} >
      <Grid container spacing={2}>
        <Grid item size={8}>
          <Controller
            control={control}
            name="name"
            defaultValue=""
            rules={{
              required: true,
              //pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                type="text"
                fullWidth
                size="small"
                label="Название"
                error={error !== undefined}
                helperText={error ? myHelper.email[error.type] : ""}
              />
            )}
          />
        </Grid>

        <Grid item size={4} sx={{ textAlign: 'end' }}>
          <Controller
            control={control}
            name="active"
            defaultValue={true}
            render={({ field }) => (

              <FormControlLabel
              {...field}
                control={<Android12Switch color='success' checked={field.value} />}
                label="Активна"
              />

            )}
          />
        </Grid>

      </Grid>

      <Grid
        container
        direction="row"
        sx={{
          justifyContent: "flex-end",
          alignItems: "center",
          pt: 2
        }}
        spacing={2}
      >
        <Button color="primary" sx={{ color: '#FFF' }} variant="contained" type="button" onClick={closeForm} >{words.cancel}</Button>
        <Button color="secondary" variant="contained" type="submit"  >{words.add}</Button>
      </Grid>
    </Box>
  );
}

export default NewObjectType