import React from 'react';
import { useForm, Controller } from "react-hook-form";
import {
  Box,
  Button,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Grid from '@mui/material/Grid2';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import 'dayjs/locale/ru';
import dayjs from 'dayjs';
import Android12Switch from '../Dashboard/components/Android12Switch';
import AutocompleteOptimum from '../Dashboard/components/AutocompleteOptimum';
//import { ContentCutOutlined } from '@mui/icons-material';

const myHelper = {
  email: {
    required: "Заполните поле",
    pattern: "Не корректный формат e-mail"
  },
  password: {
    required: "Заполните поле"
  },
  text: {
    required: "Заполните поле"
  }
};

const NewCompanyUser = ({ setOpenForm }) => {

  const [userRegions, setUserRegions] = useState([])
  const [allRegions, setAllRegions] = useState(false)
console.log(userRegions);

  const words = useSelector(state => state.language.words)
  const job_title = useSelector(state => state.companyUsersSettings.job_title)
  const profils = useSelector(state => state.companyUsersSettings.profils)
  const job_groupe = useSelector(state => state.companyUsersSettings.job_groupe)
  const regions = useSelector(state => state.regions.regions)

  const { control, handleSubmit } = useForm({
    reValidateMode: "onBlur"
  });

  const handleOnSubmit = (evt) => {
    console.log(evt);
  };

  const handleChengeRegions = (id) => {
    if (userRegions.includes(id)) {setUserRegions([...userRegions.filter(ur=>ur!==id)])} else {setUserRegions([...userRegions, id])} 
  }

  return (
    <Box component="form" onSubmit={handleSubmit(handleOnSubmit)} sx={{ borderRadius: '10px', p: 3 }} >
      <Grid container spacing={2}
        sx={{
          justifyContent: "flex-end",
          alignItems: "center",
        }} >

        <Grid item size={6}>
          <Controller
            control={control}
            name="first_name"
            defaultValue=""
            rules={{
              required: true,
              //pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                type="text"
                fullWidth
                size="small"
                label={words.first_name}
                error={error !== undefined}
                helperText={error ? myHelper.email[error.type] : ""}
              />
            )}
          />
        </Grid>

        <Grid item size={6}>
          <Controller
            control={control}
            name="last_name"
            defaultValue=""
            rules={{
              required: true,
              //pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                type="text"
                fullWidth
                size="small"
                label={words.last_name}
                error={error !== undefined}
                helperText={error ? myHelper.email[error.type] : ""}
              />
            )}
          />
        </Grid>


        <Grid item size={6}>
          <Controller
            control={control}
            name="iin"
            defaultValue=""
            rules={{
              required: true,
              //pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                type="text"
                fullWidth
                size="small"
                label={words.iin}
                error={error !== undefined}
                helperText={error ? myHelper.email[error.type] : ""}
              />
            )}
          />
        </Grid>

        <Grid item size={6}>
          <Controller
            control={control}
            name="signatory"
            defaultValue=""
            rules={{
              required: true,
              //pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                type="text"
                fullWidth
                size="small"
                label={words.signatory}
                error={error !== undefined}
                helperText={error ? myHelper.email[error.type] : ""}
              />
            )}
          />
        </Grid>

        <Grid item size={6}>
          <Controller
            control={control}
            name="login"
            defaultValue=""
            rules={{
              required: true,
              //pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                type="text"
                fullWidth
                size="small"
                label={words.login}
                error={error !== undefined}
                helperText={error ? myHelper.email[error.type] : ""}
              />
            )}
          />
        </Grid>

        <Grid item size={6}>
          <Controller
            control={control}
            name="password"
            defaultValue=""
            rules={{
              required: true,
              //pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                type="text"
                fullWidth
                size="small"
                label={words.password}
                error={error !== undefined}
                helperText={error ? myHelper.email[error.type] : ""}
              />
            )}
          />
        </Grid>

        <Grid item size={4}>
          <AutocompleteOptimum
            control={control}
            name="job_title_id"
            options={job_title}
            label={words.job_title_id}
            myHelper={myHelper}
          />
        </Grid>

        <Grid item size={4}>
          <AutocompleteOptimum
            name="profile_id"
            options={profils}
            label={words.profile_id}
            control={control}
            myHelper={myHelper}
          />
        </Grid>

        <Grid item size={4}>
          <AutocompleteOptimum
            name="job_groupe_id"
            options={job_groupe}
            label={words.job_groupe_id}
            control={control}
            myHelper={myHelper}
          />
        </Grid>

        <Grid item size={12}>
          <Typography sx={{p:0}} variant="subtitle2" >
            <strong>{words.select_regions}</strong>  {words.select_regions_captions}
          </Typography>
          <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                        justifyContent: "flex-start",
                        alignItems: "center",    
                        mb: 0.5  
                    }}
                >
          <Typography variant="subtitle2" > <strong>{words.select_all_regions}</strong> </Typography>
          <Android12Switch color='success' checked={allRegions}  onChange={()=>setAllRegions(!allRegions)} />
          </Stack>
          {regions.map((r) => (
            <Button sx={{m: 0.5}} color={userRegions.includes(r.id)? 'success' : 'primary'} disabled={allRegions} key={r.id} onClick={()=>handleChengeRegions(r.id)} variant="contained" size="small">
              {r.name}
            </Button>
          ))}
        </Grid>


        <Grid item size={3} sx={{ textAlign: 'end' }}>
          <Controller
            control={control}
            name="active"
            defaultValue={true}
            render={({ field }) => (

              <FormControlLabel
                {...field}
                control={<Android12Switch color='success' checked={field.value} />}
                label={words.active}
              />

            )}
          />
        </Grid>

      </Grid>

      <Grid
        container
        direction="row"
        sx={{
          justifyContent: "flex-end",
          alignItems: "center",
          pt: 2
        }}
        spacing={2}
      >
        <Button color="primary" sx={{ color: '#FFF' }} variant="contained" type="button" onClick={() => setOpenForm(false)} >{words.cancel}</Button>
        <Button color="secondary" variant="contained" type="submit"  >{words.add}</Button>
      </Grid>
    </Box>
  );
}

export default NewCompanyUser