import * as React from 'react';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import TableType1 from '../../components/TableType1';
import { Button, Collapse, Stack } from '@mui/material';
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import NewFuel from '../../../Forms/NewFuel';

const Fuel = () => {
    
    const words = useSelector(state => state.language.words)
    const [open, setOpen] = React.useState(false);
    const fuel =  useSelector(state => state.fuelSettings.fuel)
   
    const columnsObj = [
        {
            field: 'id',
            headerName: 'ID',
            align: 'center',
            headerAlign: 'center',
            width: 100,
        },       
        {
            field: 'name',
            headerName: words.name,
            width: 230,
        },       
        {
            field: 'klass',
            headerName: words.klass,
            width: 230,
        }        
    ]

    return (<Stack
        direction="row"
        spacing={2}
        sx={{
            justifyContent: "space-between",
            alignItems: "flex-start",
        }}
    >
        <Stack
            direction="column"
            spacing={2}
            sx={{
                justifyContent: "flex-end",
                alignItems: "flex-end",
            }}
        >
            <Stack
                direction="row"
                spacing={0}
                sx={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    pt: 1
                }}
            >

                <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                        justifyContent: "flex-end",
                        alignItems: "flex-start",
                    }}
                >
                    <Button onClick={() => setOpen(true)} variant="contained" color='success' startIcon={<PinDropOutlinedIcon />}>
                        {words.add + ' ' + words.gas}
                    </Button>

                    <Button variant="contained" sx={{ backgroundColor: 'rgb(90, 90, 90);', p: '8px 0px 8px 12px;', color: 'rgb(255, 255, 255)', minWidth: 0 }} startIcon={<AutorenewOutlinedIcon />}>
                    </Button>

                </Stack>
            </Stack>

            <Collapse in={open} timeout="auto" unmountOnExit sx={{ width: '100%' }}>
                <NewFuel close={setOpen} />
            </Collapse>

            <div style={{ marginTop: '15px'}}>
                <TableType1 data={fuel}  columns={columnsObj}  />
            </div>
        </Stack>
    </Stack>

    )
}

export default Fuel