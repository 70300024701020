//import s from './MainPanelAdministration.module.css';
import React from 'react';
import { connect } from 'react-redux';
import AddGroupe from './AddGroupe';

class AddGroupeContainer extends React.PureComponent {

    render() {
        return (<AddGroupe
            close={this.props.close}
        />)
    }
}

const mapStateToProps = (state) => {
    return {
    }
};

export default connect(mapStateToProps, {
   

})(AddGroupeContainer)
