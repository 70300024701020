//import s from './MainPanelAdministration.module.css';
import React from 'react';
import { connect } from 'react-redux';
import Regions from './Regions';

class RegionsContainer extends React.PureComponent {

    render() {
        return (<Regions
        />)
    }
}

const mapStateToProps = (state) => {
    return {
    }
};

export default connect(mapStateToProps, {
   

})(RegionsContainer)
