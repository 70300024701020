import ConnectToServer from "../API/ConnectToServer";

//Action type
const SET_TYPE = 'OBJECTS/SET_TYPE';



//Started props
let initialState = {
    companyUsers: [
        {id: 1, first_name: 'Alex', last_name: 'Admin', avatar: '', region_id: 0, job_groupe_id: 0,  job_title_id: 0, iin: '000', signatory: 'ADMIN B.', signature: '', seal: '', profile_id: 0, login: 'admin@petrolsoft.kz', password: '123456', key: '123456789', language_id: 0, user_created_id: 0, active: true, date: '25-03-2025', time: '12:32', is_delete: false},
        {id: 2, first_name: 'User', last_name: 'User', avatar: '', region_id: 1, job_groupe_id: 1, job_title_id: 1, iin: '000', signatory: 'ADMIN B.', signature: '', seal: '', profile_id: 2, login: 'user@optimum.kz', password: '123456', key: '123456789', language_id: 0, user_created_id: 0, active: false, date: '25-03-2025', time: '12:32', is_delete: true},
    ],
    profils: [
        {id: 0, name: 'Администратор', active: true},
        {id: 1, name: 'Сотрудник', active: true},
        {id: 2, name: 'ОП', active: true}
    ],
    job_groupe: [
        {id: 0, name: 'ЦА', active: true},
        {id: 1, name: 'Офис', active: true},
        {id: 2, name: 'ОП', active: true}
    ],
    job_title: [
        {id: 0, name: 'Начальник ОИТ', active: true},
        {id: 1, name: 'Начальник ОП', active: true},
        {id: 2, name: 'Менеджер ОП', active: true}
    ]

};

//Reducers functions SET_LOGOUT
const companyUsersReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_TYPE:
            return {
                ...state,
                selectType: action.selectType
            }

        default:
            return state;
    }
}

//Action Creators functions

const setObjectType = (data) => {
    return { type: SET_TYPE, selectType: data }
}


// //Thunk functions

// export const authUserRequest = (obj) => {
//     return async (dispatch) => {
//         //dispatch(setErrorAuth(""))
//         //dispatch(toggleIsFetching(true))
//         const data = await ConnectToServer.getAuthUser(obj)
//         if (data) {
//             if (data.success === 1) {
//                 dispatch(setAuthUser(data.authUser))
//                 // const pairDay = Math.ceil((new Date(parseInt(data.authUser.datePay.slice(6, 10)), parseInt(data.authUser.datePay.slice(3, 5)) - 1, parseInt(data.authUser.datePay.slice(0, 2)) + 1) - new Date()) / (1000 * 3600 * 24));
//                 // dispatch(setDayPay(pairDay))
//                 // if (pairDay > 0) {
//                 //     dispatch(setAuthUser(data.authUser))

//                 // } else {
//                 //     dispatch({ type: 'OLDAPPLICATION/SET_BACKDROP', data: false })
//                 //     dispatch(setUserPay(false))
//                 // }

//             } else {
//                 console.log(data.message)
//                 //выход
//                 localStorage.clear("finupkzuser")
//                 window.location.reload()
//                 //dispatch(toggleIsFetching(false))
//             }
//         }
//     }
// }


export default companyUsersReducer;