import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridActionsCellItem, GridRowEditStopReasons, GridRowModes, GridToolbarContainer } from '@mui/x-data-grid';
import { ruRU } from '@mui/x-data-grid/locales';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import { Button, Stack, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';

const CustomNoRowsOverlay = () => {
  return (
    <Typography variant="hoverline" gutterBottom display="block" color="#000000" sx={{ pt: "15px", textAlign: "center" }}>
      Нет записи
    </Typography>
  )
}

function EditToolbar(props) {
  const { setRows, setRowModesModel, rows } = props;
  const words = useSelector(state => state.language.words)

  const handleClick = () => {
    const id = rows.map(r => r.id).sort()[rows.length - 1] + 1
    setRows((oldRows) => {
      return [{ id, region_id: 'Астана', group_id: 0, fuel_name_id: 0, active: true, isNew: true, update: true }, ...oldRows]
    });
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'tovarUsluga' },
    }));

  };

  return (
    <GridToolbarContainer
      sx={{
        justifyContent: "flex-end",
        alignItems: "center",
        pt: 1,
        pr: 1
      }}
    >
      <Stack
        direction="row"
        spacing={1}
        sx={{
          justifyContent: "flex-end",
          alignItems: "center",
          pt: 0,
          pb: 1
        }}
      >
        <Button onClick={handleClick} variant="contained" color='success' startIcon={<PinDropOutlinedIcon />}>
          {words.add + ' ' + words.record}
        </Button>
        <Button variant="contained" sx={{ backgroundColor: 'rgb(90, 90, 90);', p: '8px 0px 8px 12px;', color: 'rgb(255, 255, 255)', minWidth: 0 }} startIcon={<AutorenewOutlinedIcon />}>
        </Button>
      </Stack>
    </GridToolbarContainer>
  );
}


function TableType2({ data, columns }) {
  const words = useSelector(state => state.language.words)
  const [rows, setRows] = React.useState(data);
  const [rowModesModel, setRowModesModel] = React.useState({});
  
  // const alldata = useSelector(state => state.data.data)

  useEffect(() => {
    setRows(data)
  }, [data])

  useEffect(() => {
    console.log(rows);

  }, [rows])

  const clienW = document.documentElement.clientWidth;
  
  const handleRowEditStop = (params, event) => {       
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleRowEditStart = (params, event) => { 
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id) => () => {   
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (acti) => () => {
    setRowModesModel({ ...rowModesModel, [acti]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {

    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    //setAllTotal(AllTotal + newTotal)    
    const updatedRow = { ...newRow, isNew: false, update: true };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleCopyClick = (row) => () => {
    const id = rows.map(r => r.id).sort()[rows.length - 1] + 1
    setRows((oldRows) => {
      return [{ ...row, id, isNew: true, update: true }, ...oldRows]
    });
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'tovarUsluga' },
    }));
    
  }

  const col = [...columns,

  {
    field: 'active',
    headerName: words.active,
    width: 100,
    editable: true,
    align: 'center',
    headerAlign: 'center',
    type: 'boolean',
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: words.actions,
    width: 130,
    cellClassName: 'actions',
    disableColumnMenu: true,
    hideSortIcons: true,
    getActions: (params) => {
      const { id, row } = params
      
      
      const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

      if (isInEditMode) {
        return [
          <GridActionsCellItem
            icon={<SaveIcon />}
            label="Save"
            color="inherit"
            onClick={handleSaveClick(id)}
          />,
          <GridActionsCellItem
            icon={<CancelIcon />}
            label="Cancel"
            className="textPrimary"
            onClick={handleCancelClick(id)}
            color="inherit"
          />,
        ];
      }

      return [
        <GridActionsCellItem
          icon={<EditNoteIcon />}
          label="Редактировать"
          onClick={handleEditClick(id)}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={<ContentCopyRoundedIcon />}
          label="Копировать"
          onClick={handleCopyClick(row)}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Удалить"
          onClick={handleDeleteClick(id)}
          color="inherit"
        />,
      ];
    },
  },
  ];

  return (
    <Box
      sx={{
        height: '100%',
        maxWidth: clienW - 284,

        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
        flex: 1
      }}
    >
      <Box sx={{ height: '100% !important', width: "100% !important" }}>
        <DataGrid
          rows={rows}
          localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
          disableDensitySelector
          
          editMode='row'
          columnGroupingModel={[
            {
              groupId: words.it_works,
              headerAlign: 'center',
              children: [{ field: 'start_date' }, { field: 'end_date' }],
            },
          ]}
          disableSelectionOnClick 
          disableRowSelectionOnClick
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          density="compact"
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          sx={{
            fontSize: "16px",

            '.MuiDataGrid-booleanCell[data-value="true"]': {
              color: '#009d3f',
            },
          }}
          columns={col}
          columnGroupHeaderHeight={36}
          slots={{
            toolbar: EditToolbar,
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          slotProps={{
            toolbar: { setRows, setRowModesModel, rows },
          }}         
          onRowEditStart={handleRowEditStart}
        />
      </Box>
    </Box>
  );
}

export default TableType2;