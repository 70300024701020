//import s from './MainPanelAdministration.module.css';
import React from 'react';
import { connect } from 'react-redux';
import CompanyUsers from './CompanyUsers';

class CompanyUsersContainer extends React.PureComponent {

    render() {
        return (<CompanyUsers    
            setFrom={this.props.setFrom} 
            setOpenForm={this.props.setOpenForm}  
            setTitle={this.props.setTitle}      
        />)
    }
}

const mapStateToProps = (state) => {
    return {
    }
};

export default connect(mapStateToProps, {


})(CompanyUsersContainer)
