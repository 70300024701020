//import s from './MainPanelAdministration.module.css';
import React from 'react';
import { connect } from 'react-redux';
import Groups from './Groups';

class GroupsContainer extends React.PureComponent {

    render() {
        return (<Groups
        />)
    }
}

const mapStateToProps = (state) => {
    return {
    }
};

export default connect(mapStateToProps, {
   

})(GroupsContainer)
