//import s from './MainPanelAdministration.module.css';
import React from 'react';
import { connect } from 'react-redux';
import Objects from './Objects';

class ObjectsContainer extends React.PureComponent {

    render() {
        return (<Objects
            objectType={this.props.objectType}
        />)
    }
}

const mapStateToProps = (state) => {
    return {
    }
};

export default connect(mapStateToProps, {


})(ObjectsContainer)
