//import s from './MainPanelAdministration.module.css';
import React from 'react';
import { connect } from 'react-redux';
import Dashboard from './Dashboard';

class DashboardContainer extends React.PureComponent {

    render() {
        return (<Dashboard                     
                    
                />)
    }
}

const mapStateToProps = (state) => {
    return {   
    }
};

export default connect(mapStateToProps, {     
    
})(DashboardContainer)
