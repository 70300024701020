import ConnectToServer from "../API/ConnectToServer";

//Action type
const SET_TYPE = 'OBJECTS/SET_TYPE';



//Started props
let initialState = {
    fuel: [
        {id: 0, name: 'AI92K4', klass: 'Евро-4', active: true},
        {id: 1, name: 'AI95K4', klass: 'Евро-4', active: true},
        {id: 2, name: 'DTK4', klass: 'Евро-4', active: true},
    ],
    prices: [
        {id: 0, region_id: 1, group_id: 1, fuel_name_id: 0, price: 205, nds: true, value_nds: 24.6, price_without_nds: 180.4, start_date: '02-13-2025', end_date: '03-31-2025', client_id: 0, contract_id: 0, user_created_id: 0, date: '10-02-2025', time: '12:33', individual: true, active: true},
        {id: 1, region_id: 2, group_id: 2, fuel_name_id: 1, price: 295, nds: false, value_nds: 0, price_without_nds: 295, start_date: '02-13-2025', end_date: '03-31-2025', client_id: 0, contract_id: 0, user_created_id: 0, date: '10-02-2025', time: '12:33', individual: false, active: true},
    ]

};

//Reducers functions SET_LOGOUT
const fuelReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_TYPE:
            return {
                ...state,
                selectType: action.selectType
            }

        default:
            return state;
    }
}

//Action Creators functions

const setObjectType = (data) => {
    return { type: SET_TYPE, selectType: data }
}


// //Thunk functions

// export const authUserRequest = (obj) => {
//     return async (dispatch) => {
//         //dispatch(setErrorAuth(""))
//         //dispatch(toggleIsFetching(true))
//         const data = await ConnectToServer.getAuthUser(obj)
//         if (data) {
//             if (data.success === 1) {
//                 dispatch(setAuthUser(data.authUser))
//                 // const pairDay = Math.ceil((new Date(parseInt(data.authUser.datePay.slice(6, 10)), parseInt(data.authUser.datePay.slice(3, 5)) - 1, parseInt(data.authUser.datePay.slice(0, 2)) + 1) - new Date()) / (1000 * 3600 * 24));
//                 // dispatch(setDayPay(pairDay))
//                 // if (pairDay > 0) {
//                 //     dispatch(setAuthUser(data.authUser))

//                 // } else {
//                 //     dispatch({ type: 'OLDAPPLICATION/SET_BACKDROP', data: false })
//                 //     dispatch(setUserPay(false))
//                 // }

//             } else {
//                 console.log(data.message)
//                 //выход
//                 localStorage.clear("finupkzuser")
//                 window.location.reload()
//                 //dispatch(toggleIsFetching(false))
//             }
//         }
//     }
// }


export default fuelReducer;