//import s from './MainPanelAdministration.module.css';
import React from 'react';
import { connect } from 'react-redux';
import ObjectType from './ObjectType';

class ObjectTypeContainer extends React.PureComponent {

    render() {
        return (<ObjectType            
        />)
    }
}

const mapStateToProps = (state) => {
    return {
    }
};

export default connect(mapStateToProps, {


})(ObjectTypeContainer)
