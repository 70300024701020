import * as React from 'react';
import PropTypes from 'prop-types';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import MapsHomeWorkOutlinedIcon from '@mui/icons-material/MapsHomeWorkOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import RecentActorsOutlinedIcon from '@mui/icons-material/RecentActorsOutlined';
import FolderCopyOutlinedIcon from '@mui/icons-material/FolderCopyOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import HearingOutlinedIcon from '@mui/icons-material/HearingOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import LocalGasStationOutlinedIcon from '@mui/icons-material/LocalGasStationOutlined';
import {
    Avatar,
    Drawer,
    Box,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemButton,
    ListItemText,
    Typography,
    Collapse,
} from '@mui/material';
import logo from '../../Img/logo.png'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { useCallback } from 'react';

const drawerWidth = 260;

function SideBar({ window1, mobileOpen, setContent, handleDrawerToggle }) {
    const words = useSelector(state => state.language.words)
    const [open, setOpen] = React.useState(false);
    const [openUser, setOpenUser] = React.useState(false);
    const [openObjects, setOpenObjects] = React.useState(false);
    // const [openUserMenu, setOpenUserMenu] = React.useState(false);
    // const [openFNOMenu, setOpenFNOMenu] = React.useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const typeObjects =  useSelector(state => state.objectsSettings.objectType)
   
    
    const handleListItemClick = (event, index, t) => {
        setSelectedIndex(index)
        
        if (t) {
            setContent(index - t.id, t)
        } else {
            setContent(index)
        }
    };

    const navigate = useNavigate();

   
    const handleClick = () => {
        setOpen(!open);
    };

    const handleClickUser = () => {
        setOpenUser(!openUser);
    };

    const handleClickObejcts = () => {
        setOpenObjects(!openObjects);
    }

    // const handleClickFNOMenu = (event) => {
    //     setOpenFNOMenu(!openFNOMenu);
    // };

    // const handleClickUserMenu = (event) => {
    //     setOpenUserMenu(!openUserMenu);
    // };

    
    const drawer = (<>
        <Box sx={{ alignItems: 'center', textAlign: 'center', height: '48px', p: 1 }}>
            <img alt="Gasenergy" src={logo} style={{height: '45px'}} />
        </Box>

        <div style={{ padding: '4px', paddingBottom: '0' }}>
            <List component="nav" sx={{ mt: '0' }}>
                <ListItem disablePadding>
                    <ListItemButton
                        selected={selectedIndex === 0}
                        onClick={(event) => handleListItemClick(event, 0)}
                        sx={{ pt: 0.6, pb: 0.6, borderRadius: '5px' }}>
                        <ListItemIcon sx={{ minWidth: '28px' }}>
                            <HomeOutlinedIcon color='secondary' sx={{ width: '18px', opacity: '0.5' }} />
                        </ListItemIcon>
                        <ListItemText >
                            <Typography sx={{ fontSize: '0.875rem', fontWeight: '500' }} >
                                {words.home}
                            </Typography>
                        </ListItemText>
                    </ListItemButton>
                </ListItem>                   
            </List>
        </div>

        <Box sx={{ pt: 0, pb: 0}} >
        <List component="nav" sx={{ pt: 0, pb: 0 }} >
                <ListItem sx={{ pt: 0}}>                    
                        <ListItemText >
                            <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#a5a5a5', opacity: '40%' }} >
                                {words.titelMNU1}
                            </Typography>                            
                        </ListItemText>                        
                </ListItem>                
                <Divider sx={{ borderColor: '#a5a5a5', mt: -1, ml: 2, mr: '210px', opacity: '40%', borderWidth: '1px' }} />
            </List>
        </Box>

        <div style={{ padding: '4px', paddingTop: '0' }}>
            <List component="nav">
                <ListItem disablePadding>
                    <ListItemButton
                        selected={selectedIndex === 1}
                        onClick={handleClickUser}
                        sx={{ pt: 0.3, pb: 0.3, borderRadius: '5px' }}>
                        <ListItemIcon sx={{ minWidth: '28px' }}>
                            <PeopleAltOutlinedIcon color='secondary' sx={{ width: '18px', opacity: '0.5' }} />
                        </ListItemIcon>
                        <ListItemText >
                            <Typography sx={{ fontSize: '0.875rem'}} >
                                {words.users}
                            </Typography>
                        </ListItemText>
                        {openUser ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                </ListItem>  
                <Collapse in={openUser} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton
                            sx={{ pl: 4, pt: 0.5, pb: 0.5, borderRadius: '5px' }}
                            selected={selectedIndex === 11}
                            onClick={(event) => handleListItemClick(event, 11)}
                        >
                            <Typography sx={{ fontSize: '0.875rem', fontWeight: '500' }} >
                                {words.listUsers}
                            </Typography>
                        </ListItemButton>
                        <ListItemButton
                            sx={{ pl: 4, pt: 0.5, pb: 0.5, borderRadius: '5px' }}
                            selected={selectedIndex === 12}
                            onClick={(event) => handleListItemClick(event, 12)}
                        >
                            <Typography sx={{ fontSize: '0.875rem', fontWeight: '500' }} >
                                {words.historyUsers}
                            </Typography>
                        </ListItemButton>
                        <ListItemButton
                            sx={{ pl: 4, pt: 0.5, pb: 0.5, borderRadius: '5px' }}
                            selected={selectedIndex === 13}
                            onClick={(event) => handleListItemClick(event, 13)}
                        >
                            <Typography sx={{ fontSize: '0.875rem', fontWeight: '500' }} >
                                {words.profileUsers}
                            </Typography>
                        </ListItemButton>
                    </List>
                </Collapse>
                

                <ListItem disablePadding>
                    <ListItemButton
                        selected={selectedIndex === 3}
                        onClick={(event) => handleListItemClick(event, 3)}
                        sx={{ pt: 0.3, pb: 0.3, borderRadius: '5px' }}>
                        <ListItemIcon sx={{ minWidth: '28px' }}>
                            <MonitorHeartOutlinedIcon color='secondary' sx={{ width: '18px', opacity: '0.5' }} />
                        </ListItemIcon>
                        <ListItemText >
                            <Typography sx={{ fontSize: '0.875rem' }} >
                                {words.monitoring}
                            </Typography>
                        </ListItemText>
                    </ListItemButton>
                </ListItem>

                

                <ListItem disablePadding>
                    <ListItemButton
                        selected={selectedIndex === 5}
                        onClick={(event) => handleListItemClick(event, 5)}
                        sx={{ pt: 0.3, pb: 0.3, borderRadius: '5px' }}>
                        <ListItemIcon sx={{ minWidth: '28px' }}>
                            <RecentActorsOutlinedIcon color='secondary' sx={{ width: '18px', opacity: '0.5' }} />
                        </ListItemIcon>
                        <ListItemText >
                            <Typography sx={{ fontSize: '0.875rem' }} >
                                {words.suppliers}
                            </Typography>
                        </ListItemText>
                    </ListItemButton>
                </ListItem>

                <ListItem disablePadding>
                    <ListItemButton
                        selected={selectedIndex === 6}
                        onClick={(event) => handleListItemClick(event, 6)}
                        sx={{ pt: 0.3, pb: 0.3, borderRadius: '5px' }}>
                        <ListItemIcon sx={{ minWidth: '28px' }}>
                            <FolderCopyOutlinedIcon color='secondary' sx={{ width: '18px', opacity: '0.5' }} />
                        </ListItemIcon>
                        <ListItemText >
                            <Typography sx={{ fontSize: '0.875rem' }} >
                                {words.docs}
                            </Typography>
                        </ListItemText>
                    </ListItemButton>
                </ListItem>

                

                <ListItem disablePadding>
                    <ListItemButton
                        selected={selectedIndex === 8}
                        onClick={(event) => handleListItemClick(event, 8)}
                        sx={{ pt: 0.3, pb: 0.3, borderRadius: '5px' }}>
                        <ListItemIcon sx={{ minWidth: '28px' }}>
                            <PaymentsOutlinedIcon color='secondary' sx={{ width: '18px', opacity: '0.5' }} />
                        </ListItemIcon>
                        <ListItemText >
                            <Typography sx={{ fontSize: '0.875rem' }} >
                                {words.cards_cupons}
                            </Typography>
                        </ListItemText>
                    </ListItemButton>
                </ListItem>

                <ListItem disablePadding>
                    <ListItemButton
                        selected={selectedIndex === 9}
                        onClick={(event) => handleListItemClick(event, 9)}
                        sx={{ pt: 0.3, pb: 0.3, borderRadius: '5px' }}>
                        <ListItemIcon sx={{ minWidth: '28px' }}>
                            <PeopleAltIcon color='secondary' sx={{ width: '18px', opacity: '0.5' }} />
                        </ListItemIcon>
                        <ListItemText >
                            <Typography sx={{ fontSize: '0.875rem' }} >
                                {words.clients}
                            </Typography>
                        </ListItemText>
                    </ListItemButton>
                </ListItem>

                <ListItem disablePadding>
                    <ListItemButton
                        selected={selectedIndex === 10}
                        onClick={(event) => handleListItemClick(event, 10)}
                        sx={{ pt: 0.3, pb: 0.3, borderRadius: '5px' }}>
                        <ListItemIcon sx={{ minWidth: '28px' }}>
                            <CardGiftcardIcon color='secondary' sx={{ width: '18px', opacity: '0.5' }} />
                        </ListItemIcon>
                        <ListItemText >
                            <Typography sx={{ fontSize: '0.875rem' }} >
                                {words.loyalty_program}
                            </Typography>
                        </ListItemText>
                    </ListItemButton>
                </ListItem>

                
            </List>
        </div>

        <Box sx={{ pt: 0, pb: 0}} >
        <List component="nav" sx={{ pt: 0, pb: 0 }} >
                <ListItem sx={{ pt: 0}}>                    
                        <ListItemText >
                            <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#a5a5a5', opacity: '40%' }} >
                                {words.listSettings}
                            </Typography>                            
                        </ListItemText>                        
                </ListItem>                
                <Divider sx={{ borderColor: '#a5a5a5', mt: -1, ml: 2, mr: '210px', opacity: '40%', borderWidth: '1px' }} />
            </List>
        </Box>

        <div style={{ padding: '4px', paddingTop: '0' }}>
            <List component="nav">



            <ListItem disablePadding>
                    <ListItemButton
                        onClick={handleClick}
                        sx={{ pt: 0.3, pb: 0.3, borderRadius: '5px' }}>
                        <ListItemIcon sx={{ minWidth: '28px' }}>
                            <PinDropOutlinedIcon color='secondary' sx={{ width: '18px', opacity: '0.5' }} />
                        </ListItemIcon>
                        <ListItemText >
                            <Typography sx={{ fontSize: '0.875rem' }} >
                                {words.regionsAndGroups}
                            </Typography>
                        </ListItemText>
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton
                            sx={{ pl: 4, pt: 0.5, pb: 0.5, borderRadius: '5px' }}
                            selected={selectedIndex === 111}
                            onClick={(event) => handleListItemClick(event, 111)}
                        >
                            <Typography sx={{ fontSize: '0.875rem', fontWeight: '500' }} >
                                {words.regionsDirectory}
                            </Typography>
                        </ListItemButton>
                        <ListItemButton
                            sx={{ pl: 4, pt: 0.5, pb: 0.5, borderRadius: '5px' }}
                            selected={selectedIndex === 112}
                            onClick={(event) => handleListItemClick(event, 112)}
                        >
                            <Typography sx={{ fontSize: '0.875rem', fontWeight: '500' }} >
                                {words.groupsDirectory}
                            </Typography>
                        </ListItemButton>
                    </List>
                </Collapse>

                <ListItem disablePadding>
                    <ListItemButton
                        onClick={handleClickObejcts}
                        // selected={selectedIndex === 2}
                        // onClick={(event) => handleListItemClick(event, 2)}
                        sx={{ pt: 0.3, pb: 0.3, borderRadius: '5px' }}>
                        <ListItemIcon sx={{ minWidth: '28px' }}>
                            <MapsHomeWorkOutlinedIcon color='secondary' sx={{ width: '18px', opacity: '0.5' }} />
                        </ListItemIcon>
                        <ListItemText >
                            <Typography sx={{ fontSize: '0.875rem' }} >
                                {words.objects}
                            </Typography>
                        </ListItemText>
                        {openObjects ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                </ListItem>  
                <Collapse in={openObjects} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                    <ListItemButton
                            sx={{ pl: 4, pt: 0.5, pb: 0.5, borderRadius: '5px' }}
                            selected={selectedIndex === 2000}
                            onClick={(event) => handleListItemClick(event, 2000)}
                        >
                            <Typography sx={{ fontSize: '0.875rem', fontWeight: '500' }} >
                                {words.objectTypes}
                            </Typography>
                        </ListItemButton>
                        {
                        typeObjects.map((t)=><ListItemButton key={t.id}
                            sx={{ pl: 4, pt: 0.5, pb: 0.5, borderRadius: '5px' }}
                            selected={selectedIndex === 200 + t.id}
                            onClick={(event) => handleListItemClick(event, 200 + t.id, t)}
                        >
                            <Typography sx={{ fontSize: '0.875rem', fontWeight: '500' }} >
                                {t.name}
                            </Typography>
                        </ListItemButton>)
                        }
                    </List>
                </Collapse>

                <ListItem disablePadding>
                    <ListItemButton
                        selected={selectedIndex === 4}
                        onClick={(event) => handleListItemClick(event, 4)}
                        sx={{ pt: 0.3, pb: 0.3, borderRadius: '5px' }}>
                        <ListItemIcon sx={{ minWidth: '28px' }}>
                            <LocalGasStationOutlinedIcon color='secondary' sx={{ width: '18px', opacity: '0.5' }} />
                        </ListItemIcon>
                        <ListItemText >
                            <Typography sx={{ fontSize: '0.875rem' }} >
                                {words.gas}
                            </Typography>
                        </ListItemText>
                    </ListItemButton>
                </ListItem>

                <ListItem disablePadding>
                    <ListItemButton
                        selected={selectedIndex === 7}
                        onClick={(event) => handleListItemClick(event, 7)}
                        sx={{ pt: 0.3, pb: 0.3, borderRadius: '5px' }}>
                        <ListItemIcon sx={{ minWidth: '28px' }}>
                            <CreditCardOutlinedIcon color='secondary' sx={{ width: '18px', opacity: '0.5' }} />
                        </ListItemIcon>
                        <ListItemText >
                            <Typography sx={{ fontSize: '0.875rem' }} >
                                {words.pricesDirectory}
                            </Typography>
                        </ListItemText>
                    </ListItemButton>
                </ListItem>

            </List>
        </div>

        <Box sx={{ pt: 0, pb: 0}} >
        <List component="nav" sx={{ pt: 0, pb: 0 }} >
                <ListItem sx={{ pt: 0}}>                    
                        <ListItemText >
                            <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#a5a5a5', opacity: '40%' }} >
                                {words.titelMNU3}
                            </Typography>                            
                        </ListItemText>                        
                </ListItem>                
                <Divider sx={{ borderColor: '#a5a5a5', mt: -1, ml: 2, mr: '210px', opacity: '40%', borderWidth: '1px' }} />
            </List>
        </Box>

        <div style={{ padding: '4px', paddingTop: '0' }}>
            <List component="nav">
                {/* <ListItem disablePadding>
                    <ListItemButton
                        selected={selectedIndex === 12}
                        onClick={(event) => handleListItemClick(event, 12)}
                        sx={{ pt: 0.3, pb: 0.3, borderRadius: '5px' }}>
                        <ListItemIcon sx={{ minWidth: '28px' }}>
                            <ReceiptLongOutlinedIcon color='primary' sx={{ width: '18px'}} />
                        </ListItemIcon>
                        <ListItemText >
                            <Typography sx={{ fontSize: '0.875rem'}} >
                                {words.instuctions}
                            </Typography>
                        </ListItemText>
                    </ListItemButton>
                </ListItem>   */}

                <ListItem disablePadding>
                    <ListItemButton
                        selected={selectedIndex === 13}
                        onClick={(event) => handleListItemClick(event, 13)}
                        sx={{ pt: 0.3, pb: 0.3, borderRadius: '5px' }}>
                        <ListItemIcon sx={{ minWidth: '28px' }}>
                            <HearingOutlinedIcon color='primary' sx={{ width: '18px' }} />
                        </ListItemIcon>
                        <ListItemText >
                            <Typography sx={{ fontSize: '0.875rem' }} >
                                {words.logs}
                            </Typography>
                        </ListItemText>
                    </ListItemButton>
                </ListItem>  

                {/* <ListItem disablePadding>
                    <ListItemButton
                        selected={selectedIndex === 14}
                        onClick={(event) => handleListItemClick(event, 14)}
                        sx={{ pt: 0.3, pb: 0.3, borderRadius: '5px' }}>
                        <ListItemIcon sx={{ minWidth: '28px' }}>
                            <ManageAccountsOutlinedIcon color='primary' sx={{ width: '18px'}} />
                        </ListItemIcon>
                        <ListItemText >
                            <Typography sx={{ fontSize: '0.875rem' }} >
                                {words.titelMNU2}
                            </Typography>
                        </ListItemText>
                    </ListItemButton>
                </ListItem> */}

                <ListItem disablePadding>
                    <ListItemButton
                        selected={selectedIndex === 15}
                        onClick={(event) => handleListItemClick(event, 15)}
                        sx={{ pt: 0.3, pb: 0.3, borderRadius: '5px' }}>
                        <ListItemIcon sx={{ minWidth: '28px' }}>
                            <InfoOutlinedIcon color='primary' sx={{ width: '18px' }} />
                        </ListItemIcon>
                        <ListItemText >
                            <Typography sx={{ fontSize: '0.875rem' }} >
                                {words.about}
                            </Typography>
                        </ListItemText>
                    </ListItemButton>
                </ListItem>
            </List>
        </div>

        {/* <Divider sx={{ borderColor: '#fcbb42', mt: 'auto', ml: '16px', mr: '16px' }} />
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', flexGrow: 0, p: '16px' }}>

            <Avatar
                variant="outlined"
                sx={{ width: '30px', height: '30px' }}
                
            />
            <Box sx={{ minWidth: 0, flex: 1 }}>
                <Typography sx={{ fontSize: '0.875rem', fontWeight: '500' }} >TEST</Typography>
                <Typography sx={{ fontSize: '0.75rem', fontWeight: '500' }} >test</Typography>
            </Box>
            <IconButton size="sm" variant="plain" color="neutral" title={words.exit}>
                <LogoutRoundedIcon />
            </IconButton>
        </Box> */}
    </>
    );

    const container = window1 !== undefined ? () => window1().document.body : undefined;

    return (
        <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 }, bgcolor: 'background.paper' }}
            aria-label="mailbox folders"
        >
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
            >
                {drawer}
            </Drawer>
            <Drawer
                variant="permanent"
                sx={{
                    display: { xs: 'none', sm: 'block' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
                open
            >
                {drawer}
            </Drawer>
        </Box>
    );
}

SideBar.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default SideBar;