import * as React from 'react';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import TableType1 from '../../components/TableType1';
import { Button, Collapse, Stack, Typography } from '@mui/material';
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import AddGroupeContainer from '../AddGroupe/AddGroupeContainer';

const Groups = () => {
    const regions = useSelector(state => state.regions.regions)
    const words = useSelector(state => state.language.words)
    const [open, setOpen] = React.useState(false);
    const groups = useSelector(state => state.groupe.groups)
    const data = groups.map(g=>({...g, region_id: regions.filter(r=>r.id===g.region_id)[0].name }))


    const columnsGrp = [
        {
            field: 'id',
            headerName: 'ID',
            align: 'center',
            headerAlign: 'center',
            width: 100,
            editable: false
        },
        {
            field: 'region_id',
            headerName: 'Регион',
            width: 180,
            editable: false,

        },
        {
            field: 'id_main_group',
            headerName: 'Входит в группу',
            width: 180,
            editable: false,

        },
        {
            field: 'name',
            headerName: 'Название',
            width: 230,
            editable: false,
        },
    ]

    return (<Stack
        direction="row"
        spacing={2}
        sx={{
            justifyContent: "space-between",
            alignItems: "flex-start",
        }}
    >
        <Stack
            direction="column"
            spacing={2}
            sx={{
                justifyContent: "flex-end",
                alignItems: "flex-end",
            }}
        >
            <Stack
                direction="row"
                spacing={0}
                sx={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    pt: 1
                }}
            >

                <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                        justifyContent: "flex-end",
                        alignItems: "flex-start",
                    }}
                >
                    <Button onClick={()=>setOpen(true)} variant="contained" color='success' startIcon={<PinDropOutlinedIcon />}>
                        {words.addGroups}
                    </Button>

                    <Button variant="contained" sx={{ backgroundColor: 'rgb(90, 90, 90);', p: '8px 0px 8px 12px;', color: 'rgb(255, 255, 255)', minWidth: 0 }} startIcon={<AutorenewOutlinedIcon />}>
                    </Button>

                </Stack>
            </Stack>

            <Collapse in={open} timeout="auto" unmountOnExit sx={{ width: '100%' }} >
                <AddGroupeContainer close={setOpen} />
            </Collapse>

            <div style={{ marginTop: '15px', marginLeft: '0' }}>
                <TableType1 data={data} columns={columnsGrp} />
            </div>
        </Stack>
    </Stack>

    )
}

export default Groups