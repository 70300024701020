import * as React from 'react';
import dayjs from 'dayjs';


const DashboardPanel = ({
   
}) => {

    window.scroll(0, 0);

    const giveTimeDate = () => {
        //now DataTime
        const nowDate = dayjs(new Date())
        const time = (nowDate.$H > 9 ? nowDate.$H : '0' + nowDate.$H) + ':' + (nowDate.$m > 9 ? nowDate.$m : '0' + nowDate.$m)
        const date = nowDate.$D + '.' + (nowDate.$M + 1 > 9 ? nowDate.$M + 1 : '0' + (nowDate.$M + 1)) + '.' + nowDate.$y
        return { time, date}
    }

    

    return (<>
       
    </>)

}

export default DashboardPanel