import ConnectToServer from "../API/ConnectToServer";

//Action type
const SET_TYPE = 'OBJECTS/SET_TYPE';



//Started props
let initialState = {
    objectType: [{id: 0, name: 'АЗС', active: true}, {id: 1, name: 'АГЗС', active: true}],
    selectType: -1,
    objects: [
        {id: 0, name: 'Z-1', object_id: 'Z1', adress: 'adress str. 25', group_id: 1, object_type_id: 0, date: '12-10-2025', time: '11:25', active: true},
        {id: 1, name: 'A-2', object_id: 'A2', adress: 'adress str. 25', group_id: 2, object_type_id: 0, date: '12-10-2025', time: '11:25', active: true},
        {id: 2, name: 'X-1', object_id: 'X1', adress: 'adress str. 25', group_id: 3, object_type_id: 0, date: '12-10-2025', time: '11:25', active: true},
        {id: 3, name: 'A-1', object_id: 'A1', adress: 'adress str. 25', group_id: 2, object_type_id: 1, date: '12-10-2025', time: '11:25', active: true},
        {id: 4, name: 'Z-2', object_id: 'Z2', adress: 'adress str. 25', group_id: 1, object_type_id: 1, date: '12-10-2025', time: '11:25', active: true},
        {id: 5, name: 'Z-3', object_id: 'Z3', adress: 'adress str. 25', group_id: 1, object_type_id: 0, date: '12-10-2025', time: '11:25', active: true},
    ]

};

//Reducers functions SET_LOGOUT
const objectsReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_TYPE:
            return {
                ...state,
                selectType: action.selectType
            }

        default:
            return state;
    }
}

//Action Creators functions

const setObjectType = (data) => {
    return { type: SET_TYPE, selectType: data }
}


// //Thunk functions

// export const authUserRequest = (obj) => {
//     return async (dispatch) => {
//         //dispatch(setErrorAuth(""))
//         //dispatch(toggleIsFetching(true))
//         const data = await ConnectToServer.getAuthUser(obj)
//         if (data) {
//             if (data.success === 1) {
//                 dispatch(setAuthUser(data.authUser))
//                 // const pairDay = Math.ceil((new Date(parseInt(data.authUser.datePay.slice(6, 10)), parseInt(data.authUser.datePay.slice(3, 5)) - 1, parseInt(data.authUser.datePay.slice(0, 2)) + 1) - new Date()) / (1000 * 3600 * 24));
//                 // dispatch(setDayPay(pairDay))
//                 // if (pairDay > 0) {
//                 //     dispatch(setAuthUser(data.authUser))

//                 // } else {
//                 //     dispatch({ type: 'OLDAPPLICATION/SET_BACKDROP', data: false })
//                 //     dispatch(setUserPay(false))
//                 // }

//             } else {
//                 console.log(data.message)
//                 //выход
//                 localStorage.clear("finupkzuser")
//                 window.location.reload()
//                 //dispatch(toggleIsFetching(false))
//             }
//         }
//     }
// }


export default objectsReducer;