import  {applyMiddleware, combineReducers, createStore} from 'redux';
import {thunk} from 'redux-thunk';
import authReducer from './authReducer';
import langReducer from './langReducer'
import regionReducer from './regionReducer'
import objectsReducer from './objectsReducer';
import fuelReducer from './fuelReducer';
import groupeReducer from './groupeReducer';
import companyUsersReducer from './companyUsersReducer';

let reducers = combineReducers({         
    auth: authReducer,
    language: langReducer,
    regions: regionReducer, 
    groupe: groupeReducer,
    objectsSettings: objectsReducer,
    fuelSettings:fuelReducer,
    companyUsersSettings:companyUsersReducer
});

//подключение devtools chrome - выявление ошибок через браузер хром
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const store = createStore(reducers, composeEnhancers(applyMiddleware(thunkMiddleware)));
let store = createStore(reducers, applyMiddleware(thunk));
//window.store = store;// глобальная переменная для проверки store
export default store;