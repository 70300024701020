import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './Redux/reduxStore';
import { ruRU } from '@mui/material/locale';

const theme = createTheme({
  palette: {
    primary: {
      main: '#a5a5a5' //'#49d3e2'#D9ECF2
    },
    secondary: {
      main: '#0F55A5'//#faaf0007139e
    },
    success: {
      main: 'rgb(0, 157, 63)'
    },
    secondaryYellow: {
      main: '#faaf00'
    },
    primary1: {
      main: 'rgb(0, 198, 181)'
    },
    primaryBlue: {
      main: '#D9ECF2'
    },
    chip: {
      main: '#FFEBA1'
    },
    primaryRed: {
      main: '#FF0000'
    },
    primaryBlack: {
      main: '#000000'
    },
    colorIconDate: {
      main: '#1AA6B7'
    },
    text: {
      primary: '#3c4043'
    }
  },
  components: {
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": { backgroundColor: "#F6F6F6", color: '#0F55A5', fontWeight: '600' },
          ":hover": { backgroundColor: "#F6F6F6", color: '#0F55A5' },
          "&.Mui-selected:hover": { backgroundColor: "#F6F6F6", color: '#0F55A5' }
        }
      }
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.notchedOutline" : {borderColor: 'rgb(0, 198, 181)'}
        }
      }
    },

    MuiDataGrid: {
      styleOverrides: {
        root: {
          "--unstable_DataGrid-headWeight": '700' 
        }
      }

    }
  }
}, ruRU)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Provider store={store} >
      <ThemeProvider theme={theme} >
        <App />
      </ThemeProvider>
    </Provider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
