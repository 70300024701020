import * as React from 'react';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { Button, Stack, Typography } from '@mui/material';
import TableType1 from '../../components/TableType1';
import { GridEditSingleSelectCell, useGridApiContext } from '@mui/x-data-grid';
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import NewCompanyUser from '../../../Forms/NewCompanyUser';
import Android12Switch from '../../components/Android12Switch';
import { useState } from 'react';
import { useEffect } from 'react';

function CustomTypeEditComponent(props) {
    const apiRef = useGridApiContext();

    const handleValueChange = async () => {
        await apiRef.current.setEditCellValue({
            id: props.id,
            field: 'group_id',
            value: '',
        });
    };

    return <GridEditSingleSelectCell onValueChange={handleValueChange} {...props} />;
}

const CompanyUsers = ({ setFrom, setOpenForm, setTitle }) => {

    const [delUser, setDelUser] = useState(false)
    const [data, setData] = useState([])

    const words = useSelector(state => state.language.words)
    const companyUsers = useSelector(state => state.companyUsersSettings.companyUsers)
    const job_groupe = useSelector(state => state.companyUsersSettings.job_groupe)
    const job_title = useSelector(state => state.companyUsersSettings.job_title)
    const profils = useSelector(state => state.companyUsersSettings.profils)
    const authUser = useSelector(state => state.auth.authUser)

    useEffect(()=>{
        setData(companyUsers.filter(cu=>cu.is_delete===true&&delUser===true||cu.is_delete===false).map(usr => ({ ...usr, job_title_id: job_title.filter(jt => jt.id === usr.job_title_id)[0].name, profile_id: profils.filter(p => p.id === usr.profile_id)[0].name, })))
    }, [companyUsers, delUser, setData])

    

    const columnsObj = [
        {
            field: 'id',
            headerName: 'ID',
            align: 'center',
            headerAlign: 'center',
            width: 60,
        },
        {
            field: 'first_name',
            headerName: words.user_name,
            headerAlign: 'center',
            align: 'left',
            width: 200,
            renderCell: (params) => {
                return params.row.first_name + ' ' + params.row.last_name
            },
        },
        {
            field: 'profile_id',
            headerName: words.profile_id,
            headerAlign: 'center',
            align: 'left',
            width: 200,
        },
        {
            field: 'job_title_id',
            headerName: words.job_title_id,
            headerAlign: 'center',
            align: 'left',
            width: 200,
        },
        {
            field: 'login',
            headerName: words.login,
            headerAlign: 'center',
            align: 'left',
            width: 200,
        },
        {
            field: 'date',
            headerName: words.date_time_created,
            headerAlign: 'center',
            align: 'left',
            width: 160,
            renderCell: (params) => {
                return params.row.date + ' ' + params.row.time
            },
        },
    ]

    const addUser = () => {
        setFrom(<NewCompanyUser id={0} setOpenForm={setOpenForm} />)
        setTitle(words.add + ' ' + words.user)
        setOpenForm(true)
    }

    const handleChange = (evt) => {
        setDelUser(!delUser)
    }

    return (<Stack
        direction="row"
        spacing={2}
        sx={{
            justifyContent: "space-between",
            alignItems: "flex-start",
        }}
    >
        <Stack
            direction="column"
            spacing={2}
            sx={{
                justifyContent: "flex-end",
                alignItems: "flex-end",
                width: 'min-content'
            }}
        >
            <Stack
                direction="column"
                spacing={0}
                sx={{
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    pt: 1
                }}
            >

                <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                        justifyContent: "flex-end",
                        alignItems: "flex-start",
                    }}
                >
                    <Button onClick={addUser} variant="contained" color='success' startIcon={<PersonAddOutlinedIcon />}>
                        {words.add + ' ' + words.user}
                    </Button>

                    <Button variant="contained" sx={{ backgroundColor: 'rgb(90, 90, 90);', p: '8px 0px 8px 12px;', color: 'rgb(255, 255, 255)', minWidth: 0 }} startIcon={<AutorenewOutlinedIcon />}>
                    </Button>

                </Stack>
                {authUser.profile_id===0 ? 
                <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                        justifyContent: "flex-end",
                        alignItems: "center",
                        pt: 2
                    }}
                >
                    <Typography> {words.getDeleteUsers} </Typography>
                    <Android12Switch color='success' checked={delUser}  onChange={handleChange} />
                </Stack> : <></>
                }
            </Stack>

            <div style={{ marginTop: '15px' }}>
                <TableType1 data={data} columns={columnsObj} />
            </div>
        </Stack>
    </Stack>
    )
}

export default CompanyUsers