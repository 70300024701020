//import s from './MainPanelAdministration.module.css';
import React from 'react';
import { connect } from 'react-redux';
import Prices from './Prices';

class PricesContainer extends React.PureComponent {

    render() {
        return (<Prices            
        />)
    }
}

const mapStateToProps = (state) => {
    return {
    }
};

export default connect(mapStateToProps, {


})(PricesContainer)
