import * as React from 'react';
import dayjs from 'dayjs';
import NewRegion from '../../../Forms/NewRegion';

const AddRegion = ({close}) => {     

    return (
            
            <NewRegion close={close} />
       
    )
}

export default AddRegion