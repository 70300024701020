import ConnectToServer from "../API/ConnectToServer";

//Action type
const SET_USER = 'AUTH/SET_USER';



//Started props
let initialState = {
    groups: [
        { id: 1, region_id: 1, id_main_group: '---', name: 'город Астана', active: true },
        { id: 2, region_id: 2, id_main_group: '---', name: 'Алматинская обл.', active: true },
        { id: 3, region_id: 3, id_main_group: '---', name: 'город Шымкент', active: true },
        { id: 4, region_id: 4, id_main_group: '---', name: 'Жамбылская обл.', active: false },
        { id: 5, region_id: 4, id_main_group: 'Жамбылская обл.', name: 'город Тараз', active: false },
    ]

};

//Reducers functions SET_LOGOUT
const groupeReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_USER:
            return {
                ...state,
                authUser: action.user
            }

        default:
            return state;
    }
}

//Action Creators functions

const setAuthUser = (data) => {
    return { type: SET_USER, user: data }
}


// //Thunk functions

// export const authUserRequest = (obj) => {
//     return async (dispatch) => {
//         //dispatch(setErrorAuth(""))
//         //dispatch(toggleIsFetching(true))
//         const data = await ConnectToServer.getAuthUser(obj)
//         if (data) {
//             if (data.success === 1) {
//                 dispatch(setAuthUser(data.authUser))
//                 // const pairDay = Math.ceil((new Date(parseInt(data.authUser.datePay.slice(6, 10)), parseInt(data.authUser.datePay.slice(3, 5)) - 1, parseInt(data.authUser.datePay.slice(0, 2)) + 1) - new Date()) / (1000 * 3600 * 24));
//                 // dispatch(setDayPay(pairDay))
//                 // if (pairDay > 0) {
//                 //     dispatch(setAuthUser(data.authUser))

//                 // } else {
//                 //     dispatch({ type: 'OLDAPPLICATION/SET_BACKDROP', data: false })
//                 //     dispatch(setUserPay(false))
//                 // }

//             } else {
//                 console.log(data.message)
//                 //выход
//                 localStorage.clear("finupkzuser")
//                 window.location.reload()
//                 //dispatch(toggleIsFetching(false))
//             }
//         }
//     }
// }


export default groupeReducer;