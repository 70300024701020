import * as React from 'react';
import SideBar from './components/SideBar';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { Avatar, Box, Button, Dialog, DialogContent, DialogTitle, Divider, IconButton, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import dayjs from 'dayjs';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import DashboardPanelContainer from './Panels/DashboardPanelContainer';
import RegionsContainer from './Panels/Regions/RegionsContainer';
import GroupsContainer from './Panels/Groups/GroupsContainer';
import ObjectsContainer from './Panels/Objects/ObjectsContainer';
import ObjectTypeContainer from './Panels/ObjectType/ObjectTypeContainer';
import FuelContainer from './Panels/Fuel/FuelContainer';
import PricesContainer from './Panels/Prices/PricesContainer';
import CompanyUsersContainer from './Panels/CompanyUsers/CompanyUsersContainer';

const Dashboard = ({
}) => {
    const words = useSelector(state => state.language.words)
    const authUser = useSelector(state => state.auth.authUser)
    const job_title = useSelector(state => state.companyUsersSettings.job_title)

    const handleExitUser = () => {
        localStorage.clear("userPetrolSoft")
        navigate("/")
        window.location.reload()
    };

    const nowDate = dayjs(new Date())

    const time = (nowDate.$H > 9 ? nowDate.$H : '0' + nowDate.$H) + ':' + (nowDate.$m > 9 ? nowDate.$m : '0' + nowDate.$m)
    const date = nowDate.$D + '.' + (nowDate.$M + 1 > 9 ? nowDate.$M + 1 : '0' + (nowDate.$M + 1)) + '.' + nowDate.$y
    const d = nowDate.$d.toString().split(' ')

    const [mobileOpen, setMobileOpen] = useState(false);
    const [openForm, setOpenForm] = useState(false);
    const [form, setFrom] = useState(<></>);
    const [title, setTitle] = useState('');
    const [dataCloseTask, setDataCloseTask] = useState(date);

    const [Component, setComponent] = useState({ title: words.home, content: <DashboardPanelContainer /> })

    const navigate = useNavigate()

    const setContent = (index, t) => {

        switch (index) {
            case 0:
                return setComponent({ title: words.home, content: <DashboardPanelContainer /> });

            case 4:
                return setComponent({ title: words.gas, content: <FuelContainer /> });

            case 7:
                return setComponent({ title: words.pricesDirectory, content: <PricesContainer /> });

            case 11:
                return setComponent({ title: words.listUsers, content: <CompanyUsersContainer setFrom={setFrom} setOpenForm={setOpenForm} setTitle={setTitle} /> });

            case 111:
                return setComponent({ title: words.regionsDirectory, content: <RegionsContainer /> });

            case 112:
                return setComponent({ title: words.groupsDirectory, content: <GroupsContainer /> });

            case 200:
                return setComponent({ title: t.name, content: <ObjectsContainer objectType={t} /> });

            case 2000:
                return setComponent({ title: words.objectTypes, content: <ObjectTypeContainer /> });

            default:
                return setComponent({ title: words.home, content: <DashboardPanelContainer /> });
        }
    }

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };


    return (
        <Box sx={{ display: 'flex', flexDirection: { xs: 'row', sm: 'row' } }}>

            <IconButton
                color="inherit"
                aria-label="open drawer"

                onClick={handleDrawerToggle}
                sx={{ mr: 1, top: '16px', height: '32px', width: '32px', left: '18px', border: 1, borderColor: '#636b74', borderRadius: '8px', p: '5px', display: { sm: 'none' } }}
            >
                <MenuIcon sx={{ width: '20px', height: '20px', color: '#636b74' }} />
            </IconButton>

            <SideBar setContent={setContent} mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />

            <Box
                component="main"
                sx={{ flexGrow: 1, alignItems: 'center' }}
            >
                <Box
                    component="main"
                    sx={{ flexGrow: 1, p: 1, alignItems: 'center' }}
                >
                    <Stack
                        direction="row"
                        spacing={2}
                        sx={{
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Stack
                            direction="row"
                            spacing={2}
                            sx={{
                                justifyContent: "flex-start",
                                alignItems: "center",
                            }}
                        >
                            <Typography fontSize={20} component='h6' color='primary' sx={{ lineHeight: '1.33', letterSpacing: '-0.025em', p: 0 }}>
                                {time}
                            </Typography>
                            <Typography fontSize={12} fontWeight={400} color='primary' sx={{ p: 0 }}>
                                {d[1] + ' ' + d[2] + ' ' + d[3] + ', ' + d[0]}
                            </Typography>
                        </Stack>
                        <Stack
                            direction="row"
                            spacing={1}
                            sx={{
                                justifyContent: "flex-start",
                                alignItems: "center",
                            }}
                        >
                            <Box >
                                <Typography sx={{ fontSize: '0.75rem', fontWeight: '500' }} color='primary' >{job_title.filter(jt => jt.id === authUser.job_title_id)[0].name}</Typography>
                            </Box>
                            <Avatar
                                variant="outlined"
                                sx={{ backgroundColor: '#00BCD5' }}
                                src={authUser.img}
                            >
                                {authUser.first_name.slice(0, 1)}{authUser.last_name.slice(0, 1)}
                            </Avatar>
                            <Button onClick={handleExitUser} color='secondary' startIcon={<LogoutRoundedIcon />}>
                                {words.exit}
                            </Button>
                        </Stack>
                    </Stack>
                </Box>
                <Divider sx={{ borderColor: '#EEEEEE' }} />

                <Box
                    component="main"
                    sx={{ flexGrow: 1, px: 2, alignItems: 'center' }}
                >
                    <Typography variant='h5' fontSize={24} fontWeight={400} sx={{ lineHeight: '32px', letterSpacing: '0', p: 0, pt: 1 }}>
                        {Component.title}
                    </Typography>
                    {Component.content}
                </Box>
            </Box>
            <Dialog
                open={openForm}
                onClose={() => setOpenForm(false)}
                scroll='body'
                
                maxWidth={'md'}
            >
                <DialogTitle sx={{ m: 0, p: 2, textTransform: 'uppercase' }} color="primary"  id="customized-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent dividers>
                    {form}
                </DialogContent>
            </Dialog>
        </Box >)

}

export default Dashboard