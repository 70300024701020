import React from 'react';
import {
  Autocomplete,
  TextField,
} from "@mui/material";
import { Controller } from 'react-hook-form';

  const AutocompleteOptimum = ({
    options,
    name,
    control,
    label,
    myHelper
  }) => {


    return <Controller
    control={control}
    name={name}
    defaultValue={'' || null}
    rules={{
      required: true
    }}
    fullWidth
    render={({ field: { ref, onChange, ...field }, fieldState: { error } }) => (
      <Autocomplete
        disablePortal
        fullWidth
        value={field.value}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            onChange(newValue)
          } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input  
            onChange(newValue.inputValue)
          } else {
            onChange(newValue)
          }
        }}
        id="customersId"
        options={options}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.name;
        }}
        renderOption={(props, option) => <li {...props}>{option.name}</li>}
        renderInput={(params) =>
          <TextField
            fullWidth
            {...params}
            size="small"
            label={label}
            error={error !== undefined}
            helperText={error ? myHelper.text[error.type] : ""}
          />}
      />
    )}
  />
  }


export default AutocompleteOptimum