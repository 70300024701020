import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridActionsCellItem, GridRowModes } from '@mui/x-data-grid';
import { ruRU } from '@mui/x-data-grid/locales';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import DoNotTouchOutlinedIcon from '@mui/icons-material/DoNotTouchOutlined';

function TableType1({ data, columns }) {
  const words = useSelector(state => state.language.words)
  const [rows, setRows] = React.useState(data);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const authUser = useSelector(state => state.auth.authUser)

  // const alldata = useSelector(state => state.data.data)

  useEffect(() => {
    setRows(data)
  }, [data])

  const handleEditClick = (id) => () => {

    // console.log(id);
    // setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleDeleteClick = (id) => () => {

    // setRows(rows.filter((row) => row.id !== id));
  };

  const handleBlockClick = (id) => () => {

  }

  const onRowClick = React.useCallback(
    (params) => {
      //setInfoComponent(<CardMeasurement nst={nst} setOpenFormInfo={setOpenFormInfo} row={params.row} deleteMeasurementRequest={deleteMeasurementRequest} />)
    },
    [],
  );

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const col = [...columns,

  {
    field: 'active',
    headerName: words.active,
    width: 130,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params) => params.value ? <CheckIcon color='success' /> : <CloseIcon />,
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: words.actions,
    width: 120,
    cellClassName: 'actions',
    disableColumnMenu: true,
    hideSortIcons: true,
    getActions: ({ id, row }) => {
      return row.is_delete === false ? [
        authUser.profile_id === 0 ?
          <GridActionsCellItem
            icon={row.active ? <BlockOutlinedIcon /> : <TaskAltOutlinedIcon />}
            label="Блокировать"
            onClick={handleBlockClick(id)}
            color="inherit"
          /> : <></>,
        <GridActionsCellItem
          icon={<ListAltOutlinedIcon />}
          label="Редактировать"
          onClick={handleEditClick(id)}
          color="inherit"
        />,
        authUser.profile_id === 0 ?
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Удалить"
            onClick={handleDeleteClick(id)}
            color="inherit"
          /> : <></>
      ] : [
        <GridActionsCellItem
          icon={<DoNotTouchOutlinedIcon />}
          label="Удалить"
          disabled
          color="inherit"
        />
      ]
    },
  },
  ];
  const clienW = document.documentElement.clientWidth;
  return (
    <Box
      sx={{
        height: '100%',
        maxWidth: clienW - 284,

        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
        flex: 1
      }}
    >
      <Box sx={{ height: '100% !important', width: "100% !important" }}>
        <DataGrid
          rows={rows}
          localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
          disableDensitySelector
          onRowEditStart={handleRowEditStart}
          density="compact"
          onRowClick={onRowClick}
          sx={{ fontSize: "16px", width: "inherit" }}
          columns={col}
        />
      </Box>
    </Box>
  );
}

export default TableType1;