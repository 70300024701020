import React from 'react';
import { useForm, Controller } from "react-hook-form";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  Dialog,
  DialogContent,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import Grid from '@mui/material/Grid2';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../Img/logo.png'
import { useNavigate } from 'react-router-dom';
//import { ContentCutOutlined } from '@mui/icons-material';

const myHelper = {
  email: {
    required: "Заполните поле",
    pattern: "Не корректный формат e-mail"
  },
  password: {
    required: "Заполните поле"
  }
};

const LoginForm = () => {
  const navigate = useNavigate()
  const authUser = useSelector(state => state.auth.authUser)
  
  // const formOpenReg = useSelector(state => state.auth.formOpenRegistration)
  // const openCollapse = useSelector(state => state.auth.openCollapseError)
  // const errorAuth = useSelector(state => state.auth.errorAuth)
  // const dispatch = useDispatch()

  // const setOpenFormReg = (data) => {
  //   dispatch({ type: 'AUTH/SET_OPEN_FORM_REGISTRATION', data })
  // }

  const { control, handleSubmit } = useForm({
    reValidateMode: "onBlur"
  });


  const handleOnSubmit = (evt) => {
    if (evt.login === authUser.login && evt.password === authUser.password) {
      localStorage.setItem("userPetrolSoft", JSON.stringify({ userId: 1, login: evt.login }))
      navigate('/')
      window.location.reload()
    } else {
      alert('Где-то Ошибка!')      
    }
    //authUserCheckRequest(evt)

  };

  return (
    <Box sx={{ position: 'absolute', mt: 20, left: '50%', transform: 'translate(-50%, -0%)', width: 300, bgcolor: '#fff', p: 4 }}>
      <Card sx={{ width: 380, height: 461, boxSizing: 'border-box', border: '1px solid rgb(228, 227, 227)', borderRadius: '10px', boxShadow: '5px 10px 20px 0px rgba(0, 0, 0, 0.2)', backgroundColor: 'rgb(255, 255, 255)' }}>

        <CardContent>
          <Box component="form" onSubmit={handleSubmit(handleOnSubmit)}>
            <Box sx={{ alignItems: 'center', textAlign: 'center', height: '48px', p: 1 }}>
              <img alt="Gasenergy" src={logo} style={{ height: '60px' }} />
            </Box>
            <Typography variant="h4" gutterBottom sx={{ color: 'rgb(52, 52, 52)', textAlign: 'center', m: 5 }}>
              ВХОД
            </Typography>
            {/* <Grid item xs={12} >
          <Collapse in={openCollapse}>
            <Alert severity="error">{errorAuth}</Alert>
          </Collapse>
        </Grid> */}
            <Grid container spacing={3}>
              <Grid item size={12}>
                <Controller
                  control={control}
                  name="login"
                  defaultValue=""
                  
                  rules={{
                    required: true,
                    pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      type="email"
                      fullWidth
                      size="small"
                      color="primary1"
                      label="Логин: e-mail"
                      error={error !== undefined}
                      helperText={error ? myHelper.email[error.type] : ""}
                    />
                  )}
                />
              </Grid>

              <Grid item size={12} textAlign='right' >
                <Controller
                  control={control}
                  name="password"
                  defaultValue=""
                  rules={{
                    required: true
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      type="password"
                      fullWidth
                      color="primary1"
                      size="small"
                      label="Пароль"
                      sx={{borderColor:'rgb(0, 198, 181)'}}
                      error={error !== undefined}
                      helperText={error ? myHelper.password[error.type] : ""}
                    />
                  )}
                />
                <Link href="#" underline="hover" sx={{ color: 'rgb(15, 85, 165)', textAlign: 'right' }} >
                  Забыли пароль?
                </Link>
              </Grid>


              <Grid item size={12} textAlign='center' sx={{ pt: 2 }} >
                <Button variant="contained" type="submit" sx={{ width: 192, backgroundColor: 'rgb(0, 116, 246)', color: '#fff' }} >Вход</Button>
              </Grid>
              {/* <Grid item xs={12}>
            <Button size="small" type="button">Забыли пароль?</Button>
            <Divider color='#07139e' flexItem />
          </Grid> */}

              {/* <Grid item xs={12}>

            <Button onClick={() => setOpenFormReg(true)} size="large" color="secondary" variant="contained" type="button" fullWidth>Регистрация</Button>
          </Grid> */}
            </Grid>
          </Box>
          {/* <Dialog
        open={formOpenReg}
        onClose={() => setOpenFormReg(false)}
        scroll='body'
      >
        <DialogContent>
          <RegistrationForm setOpenFormReg={setOpenFormReg} addUserPwdRequest={addUserPwdRequest} />
        </DialogContent>
      </Dialog> */}
        </CardContent>
      </Card>
    </Box>
  );
}

export default LoginForm