

import { Route, Routes, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { Alert, AlertTitle, Backdrop, CircularProgress, Slide, Snackbar } from '@mui/material';
import DashboardContainer from './Dashboard/DashboardContainer';
import LoginForm from './Forms/LoginForm';

function App() {
  const [ComponentContainer, setComponentContainer] = useState(<></>)
  const backdrop = useSelector(state => state.auth.backdrop)
  const userID = useSelector(state => state.auth.id)
  const dispatch = useDispatch()

  const setUserKey = useCallback((data) => {
    dispatch({ type: 'AUTH/SET_USER_KEY', data })
  }, [dispatch])

  useEffect(() => {
    const getStorageUser = () => {
      const user_ = localStorage.getItem("userPetrolSoft")
       
      if (user_) {
        setUserKey(JSON.parse(user_))
      } else {
        setComponentContainer(<LoginForm />)
      }
    }

    if (userID === 0) {
      getStorageUser()
    } else {
      setComponentContainer(<DashboardContainer />)
    }
  }, [userID, setComponentContainer, setUserKey])
  
  return (<>
    <main>
      <Routes>              
        <Route path="/" element={ComponentContainer} />
        <Route path="/login" element={<LoginForm />} />
      </Routes>
    </main>
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={backdrop}
    >
      <CircularProgress color="primary" />
    </Backdrop>
  </>
  );
}

export default App;
