import * as React from 'react';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import TableType1 from '../../components/TableType1';
import { Button, Collapse, Stack, Typography } from '@mui/material';
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import AddGroupeContainer from '../AddGroupe/AddGroupeContainer';
import NewObject from '../../../Forms/NewObject';
import { useEffect } from 'react';

const Objects = ({ objectType }) => {
    const objects = useSelector(state => state.objectsSettings.objects)
    const words = useSelector(state => state.language.words)
    const [open, setOpen] = React.useState(false);
    const [data, setData] = React.useState(objects.filter(o=>o.object_type_id===objectType.id));
    const typeObjects =  useSelector(state => state.objectsSettings.objectType)

    useEffect(()=>{
        setData(objects.filter(o=>o.object_type_id===objectType.id))
        setOpen(false)
    }, [objectType, objects, setData])

    const columnsObj = [
        {
            field: 'id',
            headerName: 'ID',
            align: 'center',
            headerAlign: 'center',
            width: 100,
            editable: false
        },
        {
            field: 'object_type_id',
            headerName: 'Тип Объекта',
            width: 180,
            editable: false,
            renderCell: (params) => typeObjects.filter(t=>t.id===params.value)[0].name
        },
        {
            field: 'name',
            headerName: 'Название',
            width: 230,
            editable: false,
        },
        {
            field: 'object_id',
            headerName: 'Идентификатор',
            width: 180,
            editable: false,

        },
        {
            field: 'adress',
            headerName: 'Адрес',
            width: 180,
            editable: false,

        }
        
    ]

    return (<Stack
        direction="row"
        spacing={2}
        sx={{
            justifyContent: "space-between",
            alignItems: "flex-start",
        }}
    >
        <Stack
            direction="column"
            spacing={2}
            sx={{
                justifyContent: "flex-end",
                alignItems: "flex-end",
            }}
        >
            <Stack
                direction="row"
                spacing={0}
                sx={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    pt: 1
                }}
            >

                <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                        justifyContent: "flex-end",
                        alignItems: "flex-start",
                    }}
                >
                    <Button onClick={() => setOpen(true)} variant="contained" color='success' startIcon={<PinDropOutlinedIcon />}>
                        {words.add + ' ' + objectType.name}
                    </Button>

                    <Button variant="contained" sx={{ backgroundColor: 'rgb(90, 90, 90);', p: '8px 0px 8px 12px;', color: 'rgb(255, 255, 255)', minWidth: 0 }} startIcon={<AutorenewOutlinedIcon />}>
                    </Button>

                </Stack>
            </Stack>

            <Collapse in={open} timeout="auto" unmountOnExit sx={{ width: '100%' }}>
                <NewObject close={setOpen} objectType={objectType} />
            </Collapse>

            <div style={{ marginTop: '15px'}}>
                <TableType1 data={data} columns={columnsObj} />
            </div>
        </Stack>
    </Stack>

    )
}

export default Objects